/* Basic styles */
body, html, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App-header, .App-footer {
  background-color: #333;
  color: white;
  text-align: center;
  height: 10vh;
  display: grid;
  place-items: center;
}

.App-main {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-content {
  width: 100%;
  text-align: center;
  overflow-x: hidden;
}

/* Responsive iframe container */
.iframe-container {
  width: 100vw;
  height: 90vh; /* 16:9 aspect ratio */
  position: relative;
}

.iframe-container iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

